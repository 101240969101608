import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Logo from '../media/tinyhare.svg';

function MainPage() {
  return (
    <Container maxWidth="sm">
      <Toolbar disableGutters>
        <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Avatar style={{
            borderRadius: 5,
            width: 175,
            height: 175,
            marginBottom: 30
          }} alt="Tiny Hare" src={Logo} />
          <Typography variant="h3" gutterBottom>
            Tiny Hare
          </Typography>
          <Typography variant="h5" gutterBottom textAlign="center">
            Framework for high performant applications in TypeScript.
          </Typography>
          <Typography variant="body1" gutterBottom textAlign="center" color="grey">
            Currently in early alpha, stay tuned!
          </Typography>
        </Box>
      </Toolbar>
    </Container>
  );
}
export default MainPage;