import MainPage from './components/MainPage';
import TopBar from './components/TopBar';

function App() {
  return (
    <div className="App">
      <TopBar />
      <MainPage />
    </div>
  );
}

export default App;
