import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Logo from '../media/tinyhare.svg';

function TopBar() {
  return (
    <AppBar position="sticky" elevation={0} sx={{
      background: 'transparent'
    }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }} mt={2} mb={2}>
            <Avatar
              style={{
                borderRadius: 5
              }}
              alt="Tiny Hare"
              src={Logo}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopBar;